import { handleGaTracking, getDataLayer } from "../../../../../../utils/tracking"

class CallToActionMercure extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "call-to-action"

  constructor(componentHost: HTMLElement) {
    super(componentHost)

    const { pageName } = getDataLayer()

    const button: HTMLElement | null = componentHost.querySelector(".cmp-button")

    if( button ){
      switch(pageName){

        case "mercure::loyalty":

          handleGaTracking({
            node: button,
            htmlEventType: CoreJS.DomEventConstants.CLICK,
            eventName: "cta_enroll",
            additionalData: {
              cta_enroll_context: "loyalty",
              cta_name: button.innerText.trim().toLowerCase(),
              contexte: "2",
            },
          })

        break

        case "mercure::magazine::article":

        if( componentHost.classList.contains("ace-cmp-CTA") ){
        // weird root auto-encapsulation cause twice events, 
        // so we need to check if the component is from the good root with this class check

          handleGaTracking({
            node: button,
            htmlEventType: CoreJS.DomEventConstants.CLICK,
            eventName: "bloc_interact",
            additionalData: {
              bloc_name: "edito",
              bloc_interaction: "see hotel page",
            },
          })

        }

        break
      }
    }

  }
}

CoreJS.BaseComponent.registerComponent(CallToActionMercure.CLASS_NAMESPACE, CallToActionMercure, true)
